import React, { useState, useEffect } from 'react';
import { post } from '../../services/api';
import { useAuth } from '../../AuthContext';
import '../DailyReports/DailyReports.css'; // Import the CSS file

const DailyReport = () => {
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedCanteenId, setSelectedCanteenId] = useState('All');
    const [reportData, setReportData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { adminData } = useAuth();

    const canteenList = adminData
        ? adminData.canteenId.map((id, index) => ({
            canteenId: id,
            canteenName: adminData.canteenName[index]
        }))
        : [];

    useEffect(() => {
        const preferredCanteenId = localStorage.getItem('preferredCanteenId');
        if (preferredCanteenId) {
            setSelectedCanteenId(preferredCanteenId); // Set the dropdown to the stored preferred value
        }
    }, []);
    
    useEffect(() => {
        if (selectedDate) {
            fetchReport();
        }
    }, [selectedDate]);

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };

    const handleCanteenChange = (event) => {
        setSelectedCanteenId(event.target.value);
    };

    const fetchReport = async () => {
        if (!selectedDate) return;

        setLoading(true);
        setError(null);

        let requestBody;

        if (selectedCanteenId === 'All') {
            requestBody = { date: selectedDate, canteenIds: adminData.canteenId.map(id => parseInt(id)) };
        } else {
            requestBody = { date: selectedDate, canteenIds: [parseInt(selectedCanteenId)] };
        }

        try {
            const response = await post(`v1/analytics/dailyReports`, requestBody);
            setReportData(response);
        } catch (err) {
            setError('Error fetching report');
        } finally {
            setLoading(false);
        }
    };

    const handleView = async () => {
        if (!reportData) return;

        // Open a new window for the report
        const printWindow = window.open('', '', 'width=800,height=600');

        // Start building the HTML for the report
        let printContent = '<html><head><title>Daily Report</title></head><body>';
        printContent += `<h1>Daily Report for ${selectedDate}</h1>`;

        // Add table structure
        printContent += '<table border="1"><thead><tr><th>S.No</th><th>Name</th><th>Price</th><th>Stocks Sold</th><th>Total Value (without GST)</th><th>Total Value (with GST)</th></tr></thead><tbody>';

        // Loop through report data to populate the table
        reportData.items.forEach((item, index) => {
            printContent += `<tr>
                <td>${index + 1}</td>
                <td>${item.foodName}</td>
                <td>${item.foodPrice}</td>
                <td>${item.stocksSold}</td>
                <td>${item.totalValueWithoutGST}</td>
                <td>${item.totalValueWithGST}</td>
            </tr>`;
        });

        // Calculate and add total sales
        const totalParcelPrice = reportData.totalParcelPrice;
        const totalSalesWithoutGST = reportData.totalSalesWithoutGST;
        const totalSalesWithGST = reportData.totalSalesWithGST;

        printContent += '</tbody></table>';
        printContent += `<h2>Total Parcel Charges: ₹${totalParcelPrice}</h2>`;
        printContent += `<h2>Total Sales (without GST): ₹${totalSalesWithoutGST}</h2>`;
        printContent += `<h2>Total Sales (with GST): ₹${totalSalesWithGST}</h2>`;
        printContent += '</body></html>';

        // Write content to the print window and print
        printWindow.document.write(printContent);
        printWindow.document.close();
    };


    const downloadCSV = () => {
        if (!reportData) return;


        if (!reportData || !Array.isArray(reportData.items)) {
            console.error("reportData or reportData.items is undefined or not an array.");
            return;
        }

        const csvRows = [];
        csvRows.push([`SALES REPORT: (${selectedDate})`]);
        csvRows.push([]);
        const headers = ['S.No', 'Food Name', 'Price', 'Stocks Sold', 'Total Value (without GST)', 'Total Value (with GST)'];
        csvRows.push(headers.join(','));

        reportData.items.forEach((item, index) => {
            const row = [
                index + 1,
                item.foodName,
                item.foodPrice,
                item.stocksSold,
                item.totalValueWithoutGST,
                item.totalValueWithGST
            ];
            csvRows.push(row.join(','));
        });

        csvRows.push([]);
        csvRows.push([]);
        csvRows.push(['Total Parcel Charges', '', '', '', reportData.totalParcelPrice, '']);
        csvRows.push(['Total Sales (without GST)', '', '', '', reportData.totalSalesWithoutGST, '']);
        csvRows.push(['Total Sales (with GST)', '', '', '', reportData.totalSalesWithGST, '']);

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', `sales_report_${selectedDate}.csv`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <div className="daily-report-container">
            <h1>Daily Report</h1>
            <select className='report-canteen-dropdown' onChange={handleCanteenChange} value={selectedCanteenId}>
                <option value="All">All Canteens</option>
                {canteenList.map((canteen) => (
                    <option key={canteen.canteenId} value={canteen.canteenId}>
                        {canteen.canteenName}
                    </option>
                ))}
            </select>
            <input
                type="date"
                value={selectedDate}
                onChange={handleDateChange}
                max={new Date().toISOString().split('T')[0]} // Max today
            />
            <button onClick={handleView} disabled={loading || !reportData}>
                {loading ? 'Loading...' : 'View'}
            </button>
            <button onClick={downloadCSV} disabled={loading || !reportData}>
                {loading ? 'Loading...' : 'Download CSV'}
            </button>
            {error && <p>{error}</p>}
        </div>
    );
};

export default DailyReport;
