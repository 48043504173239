import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import './SearchBar.css';

const SearchBar = ({ onSearch, onSearchButtonClick, found }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showSearchButton, setShowSearchButton] = useState(false);

  const handleChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    onSearch(value);
    setShowSearchButton(!found);
  };

  const handleSearchButtonClick = () => {
    onSearchButtonClick(searchTerm);
    setShowSearchButton(false);
  };

  return (
    <div className="search-containerr">
      <input
        type="text"
        className="search-inputt"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleChange}
      />
      {showSearchButton && (
        <button className="search-button" onClick={handleSearchButtonClick}>
          <FaSearch /> Search
        </button>
      )}
    </div>
  );
};

export default SearchBar;