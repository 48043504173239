import React, { useEffect, useState } from 'react';
import { get, post } from '../../services/api';
import './Profile.css';
import { useAuth } from '../../AuthContext'; // Import the Auth context

const Profile = () => {
  const userId = localStorage.getItem('adminId');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [modifiedAt, setModifiedAt] = useState('');
  const [signedInAt, setSignedInAt] = useState('');

  // Password fields
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

  // Canteen preference fields
  const [selectedCanteenId, setSelectedCanteenId] = useState(null);
  const { adminData } = useAuth(); // Get canteen list from context
  const canteenList = adminData
    ? adminData.canteenId.map((id, index) => ({
      canteenId: id,
      canteenName: adminData.canteenName[index]
    }))
    : [];

  useEffect(() => {
    retrieveUserData();
    const savedCanteenId = localStorage.getItem('preferredCanteenId');
    if (savedCanteenId) {
      setSelectedCanteenId(savedCanteenId);
    }
  }, []);

  const retrieveUserData = async () => {
    try {
      const response = await get(`v1/admins/profile/${userId}`);
      setName(response.name);
      setEmail(response.email);
      setPhone(response.phone);
      setModifiedAt(formatDate(response.modificationTime));
      setSignedInAt(formatDate(response.signedInAt));
    } catch (error) {
      console.error('Network error', error);
    }
  };

  const formatDate = (timestamp) => {
    if (timestamp > 0) {
      const dateObj = new Date(timestamp);
      return dateObj.toLocaleString();
    } else {
      return 'N/A';
    }
  };

  const updateProfile = async () => {
    const updateRequest = {
      name,
      email,
      phone,
      modificationTime: Date.now()
    };

    try {
      await post(`v1/admins/updateProfile/${userId}`, updateRequest);
      alert('Profile updated successfully');
    } catch (error) {
      console.error('Network error', error);
    }
  };

  const resetPassword = async () => {
    if (newPassword !== confirmPassword) {
      alert('New password and confirmation do not match');
      return;
    }

    const updateRequest = {
      userId,
      currentPassword: oldPassword,
      newPassword,
      modificationTime: Date.now()
    };

    try {
      const response = await post('v1/password/updatePassword', updateRequest);
      setIsPasswordModalOpen(false);
      alert(response.message);
    } catch (error) {
      console.error('Network error', error);
    }
  };

  const applyCanteenPreference = () => {
    if (selectedCanteenId) {
      localStorage.setItem('preferredCanteenId', selectedCanteenId);
      alert('Preferred canteen updated!');
    } else {
      alert('Please select a canteen.');
    }
  };

  return (
    <div className="profile-container">
      <h2>Admin Profile</h2>
      <div className="profile-details">
        <label>Name:</label>
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
        <label>Email:</label>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        <label>Phone:</label>
        <input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} required />
        <p>Last Modified: {modifiedAt}</p>
        <p>Last Signed In: {signedInAt}</p>
      </div>

      <div className="profile-actions">
        <button onClick={updateProfile}>Save</button>
        <button onClick={() => setIsPasswordModalOpen(true)}>Reset Password</button>
      </div>

      {isPasswordModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>Reset Password</h3>
            <label>Current Password:</label>
            <input
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              required
            />
            <label>New Password:</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <label>Confirm New Password:</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <div className="modal-actions">
              <button onClick={resetPassword}>Submit</button>
              <button onClick={() => setIsPasswordModalOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      <div className="canteen-preference">
        <h3>Select Preferred Canteen</h3>
        <select
          value={selectedCanteenId}
          onChange={(e) => setSelectedCanteenId(e.target.value)}
        >
          <option value="">-- Select Canteen --</option>
          {canteenList.map((canteen) => (
            <option key={canteen.canteenId} value={canteen.canteenId}>
              {canteen.canteenName}
            </option>
          ))}
        </select>
        <button onClick={applyCanteenPreference}>Apply</button>
      </div>
    </div>
  );
};

export default Profile;
