import React, { useEffect, useState } from 'react';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { useAuth } from '../../AuthContext';
import { get, post, put } from '../../services/api';
import './Contact.css';

function Contact() {
  const { adminData } = useAuth();
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCanteenId, setSelectedCanteenId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);

  const canteenList = adminData
    ? adminData.canteenId.map((id, index) => ({
      canteenId: id,
      canteenName: adminData.canteenName[index]
    }))
    : [];

  useEffect(() => {
    const preferredCanteenId = localStorage.getItem('preferredCanteenId');
    if (preferredCanteenId) {
      setSelectedCanteenId(preferredCanteenId);
    } else if (canteenList.length > 0) {
      setSelectedCanteenId(canteenList[0].canteenId);
    }
  }, [adminData]);

  const handleCanteenChange = (event) => {
    setSelectedCanteenId(event.target.value);
  };

  const fetchContacts = async () => {
    setIsLoading(true);
    try {
      const response = await get(`v1/canteencontacts/${selectedCanteenId}`);
      setContacts(response);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCanteenId) {
      fetchContacts();
    }
  }, [selectedCanteenId]);

  const handleAddContact = () => {
    setSelectedContact(null);
    setShowModal(true);
  };

  const handleEditContact = (contact) => {
    setSelectedContact(contact);
    setShowModal(true);
  };

  const handleDeleteContact = async (contactId) => {
    if (window.confirm('Are you sure you want to delete this contact?')) {
      try {
        await put(`v1/canteencontacts/delContact/${contactId}`);
        fetchContacts();
      } catch (error) {
        console.error('Error deleting contact:', error);
      }
    }
  };

  const handleSubmit = async (formData) => {
    setIsLoading(true);
    try {
      if (selectedContact) {
        await put(`v1/canteencontacts/${selectedContact.contactId}`, formData);
      } else {
        await post(`v1/canteencontacts/${selectedCanteenId}`, formData);
      }
      fetchContacts();
    } catch (error) {
      console.error('Error saving contact:', error);
    } finally {
      setShowModal(false);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h2 className='contact-title'>Contacts</h2>
      <div className="contact-top-area">
        <select className="canteen-dropdown" onChange={handleCanteenChange} value={selectedCanteenId}>
          {canteenList.map((canteen) => (
            <option key={canteen.canteenId} value={canteen.canteenId}>
              {canteen.canteenName}
            </option>
          ))}
        </select>
        <button className="add-button" onClick={handleAddContact}>Add Contact</button>
      </div>
      {isLoading ? (
        <p>Loading contacts...</p>
      ) : (
        <div className="contact-grid">
          {contacts.map((contact) => (
            <div key={contact.contactId} className="contact-card">
              <h3>{contact.canteenName}</h3>
              <p>Phone: {contact.phone}</p>
              <p>Email: {contact.email}</p>
              <p>Address: {contact.address}</p>
              <div className="contact-card-actions">
                <button onClick={() => handleEditContact(contact)}><BiEdit /></button>
                <button onClick={() => handleDeleteContact(contact.contactId)}><BiTrash /></button>
              </div>
            </div>
          ))}
        </div>
      )}
      {showModal && (
        <ContactForm
          contact={selectedContact}
          onClose={() => setShowModal(false)}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
}

function ContactForm({ contact, onClose, onSubmit }) {
  const [phone, setPhone] = useState(contact ? contact.phone : '');
  const [email, setEmail] = useState(contact ? contact.email : '');
  const [address, setAddress] = useState(contact ? contact.address : '');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ phone, email, address });
  };

  return (
    <div className="contact-popup-overlay">
      <div className="contact-popup">
        <h2>{contact ? 'Edit Contact' : 'Add Contact'}</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Phone
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </label>
          <label>
            Email
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>
          <label>
            Address
            <textarea
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </label>
          <div className="contact-popup-actions">
            <button type="submit">Save</button>
            <button type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
