import React, { useEffect, useState } from 'react';
import { get } from '../../services/api'; // Import your API service function
import './MenuForm.css'; // Import CSS file for styling if needed

function EditMenuForm({ canteens, menuItem, onSubmit, onClose }) {
  const [formData, setFormData] = useState({
    menuItemId: menuItem.menuItemId,
    foodCategory: menuItem.foodCategory,
    categoryId: menuItem.categoryId,
    foodName: menuItem.foodName,
    foodImage: menuItem.foodImageUrl,
    foodPrice: menuItem.foodPrice,
    offerPrice: menuItem.offerPrice || '',
    stockQuantity: menuItem.stockQuantity,
    foodDesc: menuItem.foodDesc || '',
    vegFlag: menuItem.vegFlag,
    parcelable: menuItem.parcelable,
    canteenId: menuItem.canteenId,
    availability: menuItem.availability,
    menuModificationTime: Date.now(),
    isDisabled: false,
    modifiedBy: localStorage.getItem('adminId')
  });
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (formData.canteenId) {
      fetchCategories(formData.canteenId);
    }
  }, [formData.canteenId]);

  useEffect(() => {
    setFormData({
      menuItemId: menuItem.menuItemId,
      foodCategory: menuItem.foodCategory,
      categoryId: menuItem.categoryId,
      foodName: menuItem.foodName,
      foodImage: menuItem.foodImage,
      foodPrice: menuItem.foodPrice,
      offerPrice: menuItem.offerPrice || '',
      stockQuantity: menuItem.stockQuantity,
      vegFlag: menuItem.vegFlag,
      parcelable: menuItem.parcelable,
      foodDesc: menuItem.foodDesc || '',
      canteenId: menuItem.canteenId,
      availability: menuItem.availability,
      menuModificationTime: Date.now(),
      modifiedBy: localStorage.getItem('adminId')
    });
  }, [menuItem]);

  const fetchCategories = async (canteenId) => {
    setIsLoading(true);
    try {
      const response = await get(`v1/categories/${canteenId}`);
      console.log(response);
      setCategories(response);
      const selectedCategory = response.find(category => category.categoryId === menuItem.categoryId);
      if (selectedCategory) {
        setFormData((prevData) => ({
          ...prevData,
          foodCategory: selectedCategory.categoryName,
          categoryId: selectedCategory.categoryId
        }));
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const name = e.target.name;
    const file = e.target.files[0];
    setFormData({ ...formData, [name]: file });
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = categories.find(category => category.categoryId === parseInt(e.target.value));
    setFormData({
      ...formData,
      foodCategory: selectedCategory.categoryName,
      categoryId: selectedCategory.categoryId
    });
  };

  const handleToggleAvailability = () => {
    setFormData({ ...formData, availability: !formData.availability });
  };

  const handleToggleVegFlag = () => {
    setFormData({ ...formData, vegFlag: !formData.vegFlag });
  };

  const handleParcelFlagToggle = () => {
    setFormData({ ...formData, parcelable: !formData.parcelable });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const plainFormData = { ...formData };
    onSubmit(plainFormData);
  };

  const handleViewImage = () => {
    // Open a popup to load the food image URL
    window.open(formData.foodImage, '_blank', 'width=600,height=400');
  };

  return (
    <div className="add-popup-overlay" onClick={onClose}>
      <div className="add-popup" onClick={(e) => e.stopPropagation()}>
        <span className="close" onClick={onClose}>&times;</span>
        <h2>Edit Menu Item</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="canteenId">Canteen:</label>
            <select
              id="canteenId"
              name="canteenId"
              value={formData.canteenId}
              onChange={handleInputChange}
              required
            >
              {canteens.map((canteen) => (
                <option key={canteen.canteenId} value={canteen.canteenId}>
                  {canteen.canteenName}
                </option>
              ))}
            </select>
          </div>
          {!isLoading && categories.length > 0 && (
            <div className="form-group">
              <label htmlFor="foodCategory">Food Category:</label>
              <select
                id="foodCategory"
                name="foodCategory"
                value={formData.categoryId}
                onChange={handleCategoryChange}
                required
              >
                {categories.map((category) => (
                  <option key={category.categoryId} value={category.categoryId}>
                    {category.categoryName}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="form-group">
            <label htmlFor="foodName">Food Name:</label>
            <input
              type="text"
              id="foodName"
              name="foodName"
              value={formData.foodName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="foodImage">Food Image:</label>
            <button type="button" className="foodImage-button" onClick={handleViewImage}>View Image</button>
            <input
              type="file"
              id="foodImage"
              name="foodImage"
              onChange={handleFileChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="foodPrice">Food Price:</label>
            <input
              type="number"
              id="foodPrice"
              name="foodPrice"
              value={formData.foodPrice}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="offerPrice">Offer Price:</label>
            <input
              type="number"
              id="offerPrice"
              name="offerPrice"
              value={formData.offerPrice}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="foodDesc">Food Description:</label>
            <textarea
              id="foodDesc"
              name="foodDesc"
              value={formData.foodDesc}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="vegFlag">Is Vegetarian:</label>
            <div className="toggle-container">
              <label className="switch">
                <input
                  type="checkbox"
                  id="vegFlag"
                  name="vegFlag"
                  checked={formData.vegFlag}
                  onChange={handleToggleVegFlag}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="parcelableFlag">Parcel?:</label>
            <div className="toggle-container">
              <label className="switch">
                <input
                  type="checkbox"
                  id="parcelable"
                  name="parcelable"
                  checked={formData.parcelable}
                  onChange={handleParcelFlagToggle}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="stockQuantity">Stock Quantity:</label>
            <input
              type="number"
              id="stockQuantity"
              name="stockQuantity"
              value={formData.stockQuantity}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="availability">Availability:</label>
            <div className="toggle-container">
              <label className="switch">
                <input
                  type="checkbox"
                  id="availability"
                  name="availability"
                  checked={formData.availability}
                  onChange={handleToggleAvailability}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <button type="submit">Update</button>
        </form>
      </div>
    </div>
  );
}

export default EditMenuForm;
