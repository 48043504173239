import React, { useState, useEffect } from 'react';
import './PrintKOTModel.css';
import { useAuth } from '../../AuthContext';
import { FaSync } from 'react-icons/fa';
import { post } from '../../services/api';
import axios from 'axios';
import { render, Printer, Text, Line, Cut, Row } from 'react-thermal-printer';

const PrintKOTModal = ({ orders, onClose, fetchOrders }) => {

    const [selectedOrders, setSelectedOrders] = useState([]);
    const [selectionLimit, setSelectionLimit] = useState(10);
    const [selectAll, setSelectAll] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const { adminData } = useAuth();

    useEffect(() => {
        // Automatically select the first `selectionLimit` orders by default
        const defaultSelection = orders.slice(0, selectionLimit).map(order => order.orderId);
        setSelectedOrders(defaultSelection);
    }, [orders, selectionLimit]);

    const handleCheckboxChange = (orderId) => {
        setSelectedOrders((prevSelected) =>
            prevSelected.includes(orderId)
                ? prevSelected.filter((id) => id !== orderId)
                : [...prevSelected, orderId]
        );
    };

    const handleSelectionLimitChange = (event) => {
        const newLimit = Number(event.target.value);
        setSelectionLimit(newLimit);

        // Adjust the selected orders based on the new limit
        const newSelectedOrders = orders.slice(0, newLimit).map(order => order.orderId);
        setSelectedOrders(prevSelected =>
            prevSelected.filter(orderId => newSelectedOrders.includes(orderId))
        );
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedOrders([]); // Deselect all orders
        } else {
            const newSelectedOrders = orders.map(order => order.orderId); // Select all orders, not just up to selectionLimit
            setSelectedOrders(newSelectedOrders); // Select all orders
        }
        setSelectAll(!selectAll); // Toggle select all
    };

    const handleRefresh = async () => {
        // Trigger the fetchOrders function to reload the orders
        await fetchOrders();
    };

    const confirmPrint = async () => {
        try {
            // API call to update isKotPrinted
            const response = await post('/v1/kot/updateKotPrintedMultiple', { orderIds: selectedOrders });

            if (response) {
                fetchOrders(); // Refresh orders after printing
                setShowConfirmation(false); // Hide the dialog
                handleRefresh();
                setSelectedOrders([]); // Clear selected orders
            } else {
                alert('Failed to mark KOT as printed');
            }
        } catch (error) {
            console.error('Error marking KOT as printed:', error);
            alert('An error occurred while marking KOT as printed');
        }
    };

    const handleCancelPrint = () => {
        setShowConfirmation(false); // Hide the confirmation dialog
    };

    const handlePrintClick = () => {
        if (selectedOrders.length === 0) {
            alert('No orders selected to print');
            return;
        }
        handlePrint();
        setShowConfirmation(true); // Show confirmation dialog
    };

    const handlePrint = () => {
        // Create a new window for printing
        const printWindow = window.open('', '', 'width=800');
        const selectedOrderDetails = orders.filter(order => selectedOrders.includes(order.orderId));

        let printContent = '<html><head><title>Print KOT</title>';

        // Add styles specifically for printing
        printContent += `
            <style>
                /* Styling for print media */
                @media print {
                    body {
                        margin: 0;
                        padding: 0;
                        width: 80mm;  /* Set width to 80mm for thermal paper */
                        font-family: Arial, sans-serif;
                        font-size: 12px;
                    }
    
                    h1, h2, h3, p {
                        margin: 0;
                        padding: 4px 0;
                    }
    
                    ul {
                        list-style-type: none;
                        padding-left: 0;
                    }
    
                    hr {
                        border-top: 1px dashed #000;
                        margin: 10px 0;
                    }
    
                    /* Ensure each order is printed on a new page if there are multiple orders */
                    ${selectedOrderDetails.length > 1 ? `
                        .order-section {
                            page-break-after: always;  /* Page break for each order */
                        }
                    ` : ''}
    
                    /* Styling for the order section */
                    .order-section {
                        padding: 10px;
                    }
    
                    /* Styling for items list */
                    .items-list {
                        display: flex;
                        flex-direction: column;
                        margin: 0;
                        padding: 0;
                    }
    
                    .items-list li {
                        display: flex;
                        justify-content: space-between;
                        border-bottom: 1px dashed #000;
                        padding: 8px 0;
                        font-size: 14px; /* Increased font size */
                        text-transform: uppercase; /* Capitalize text */
                        line-height: 1.6; /* Increased line height */
                    }


                    .items-title {
                        display: flex;
                        justify-content: space-between;
                    }
                }
            </style>
        `;

        // Loop through each selected order and print its details
        selectedOrderDetails.forEach(order => {

            printContent += '</head><body>';
            printContent += '<div class="order-section">';
            printContent += '<h1>Kitchen Order Token (KOT)</h1>';
            printContent += `<h2>${order.canteenName}</h2>`;
            printContent += `<h3>Order ID: ${order.orderId}</h3>`;
            printContent += `<h3>KOT No: ${order.kotNumber}</h3>`;
            printContent += `<h3>Name: ${order.userName}</h3>`;
            printContent += `<h3>Ordered Time: ${formatDateToOnlyTime(order.orderedTime)}</h3>`;
            printContent += `<h3>Server: ${adminData.name}</h3>`;
            printContent += `<h3>Total Price: ${order.totalPrice}</h3><br/>`;
            if (order.isParcel) {
                printContent += '<br/><h3 class="parcel">(PARCEL)</h3>';
            }
            printContent += '</br><div class="items-title"><h2><b>ITEM</b></h2><h2><b>QTY</b></h2></div><ul class="items-list">';

            // Loop through each item in the order
            order.foodItems.forEach(item => {
                printContent += `<li><span>${item.foodName}</span><span>${item.foodQuantity}</span></li>`;
            });

            printContent += '</ul><hr>';
            printContent += '</div>';
            printContent += '</body></html>';
        });

        // Write the content to the new window
        printWindow.document.write(printContent);
        printWindow.document.close();
        printWindow.focus();

        // Trigger the print function
        printWindow.print();
    };


    const handleMultiPrintClick = () => {
        if (selectedOrders.length === 0) {
            alert('No orders selected to print');
            return;
        }
        handleMultiPrint();
        setShowConfirmation(true); // Show confirmation dialog
    };

    const handleMultiPrint = () => {
        // Create a new window for printing
        const printWindow = window.open('', '', 'width=800');
        const selectedOrderDetails = orders.filter(order => selectedOrders.includes(order.orderId));

        let printContent = '<html><head><title>Print KOT</title>';

        // Add styles specifically for printing
        printContent += `
            <style>
                /* Styling for print media */
                @media print {
                    body {
                        margin: 0;
                        padding: 0;
                        width: 80mm;  /* Set width to 80mm for thermal paper */
                        font-family: Arial, sans-serif;
                        font-size: 12px;
                    }
    
                    h1, h2, h3, p {
                        margin: 0;
                        padding: 4px 0;
                    }
    
                    ul {
                        list-style-type: none;
                        padding-left: 0;
                    }
    
                    hr {
                        border-top: 1px dashed #000;
                        margin: 10px 0;
                    }
    
                    /* Styling for the order section */
                    .order-section {
                        padding: 10px;
                    }
    
                    /* Styling for items list */
                    .items-list {
                        display: flex;
                        flex-direction: column;
                        margin: 0;
                        padding: 0;
                    }
    
                    .items-list li {
                        display: flex;
                        justify-content: space-between;
                        border-bottom: 1px dashed #000;
                        padding: 8px 0;
                        font-size: 14px; /* Increased font size */
                        text-transform: uppercase; /* Capitalize text */
                        line-height: 1.6; /* Increased line height */
                    }
    
                    .items-title {
                        display: flex;
                        justify-content: space-between;
                    }
                }
            </style>
        `;

        // Loop through each selected order and print its details
        selectedOrderDetails.forEach((order, index) => {
            // Add a page break after every two orders
            if (index > 0 && index % 2 === 0) {
                printContent += '<div style="page-break-before: always;"></div>';
            }

            if (index === 0) {
                printContent += '</head><body>';  // Only open body tag once
            }

            printContent += '<div class="order-section">';
            printContent += '<h1>Kitchen Order Token (KOT)</h1>';
            printContent += `<h2>${order.canteenName}</h2>`;
            printContent += `<h3>Order ID: ${order.orderId}</h3>`;
            printContent += `<h3>KOT No: ${order.kotNumber}</h3>`;
            printContent += `<h3>Name: ${order.userName}</h3>`;
            printContent += `<h3>Ordered Time: ${formatDateToOnlyTime(order.orderedTime)}</h3>`;
            printContent += `<h3>Server: ${adminData.name}</h3>`;
            printContent += `<h3>Total Price: ${order.totalPrice}</h3><br/>`;
            if (order.isParcel) {
                printContent += '<br/><h3 class="parcel">(PARCEL)</h3>';
            }
            printContent += '</br><div class="items-title"><h2><b>ITEM</b></h2><h2><b>QTY</b></h2></div><ul class="items-list">';

            // Loop through each item in the order
            order.foodItems.forEach(item => {
                printContent += `<li><span>${item.foodName}</span><span>${item.foodQuantity}</span></li>`;
            });

            printContent += '</ul><hr>';
            printContent += '</div></br></br>';

            // Close the body tag only after the last order
            if (index === selectedOrderDetails.length - 1) {
                printContent += '</body>';
            }
        });

        printContent += '</html>';

        // Write the content to the new window
        printWindow.document.write(printContent);
        printWindow.document.close();
        printWindow.focus();

        // Trigger the print function
        printWindow.print();
    };


    const handleNodePrint = async () => {
        const selectedOrderDetails = orders.filter(order => selectedOrders.includes(order.orderId));
        const adminName = adminData.name;

        const printData = {
            selectedOrders: selectedOrderDetails,
            adminName: adminName
        };

        try {
            // Send the POST request to the local print service
            const response = await axios.post('http://localhost:4000/print',
                printData);

            if (response) {
                alert('Receipt sent to printer!');
            } else {
                console.error('Failed to print receipt');
                alert('Error printing receipt');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Could not connect to printer');
        }
    };

    const handleTestNodePrint = async () => {

        try {
            // Send the POST request to the local print service
            const response = await axios.post('http://localhost:4000/testprint');

            if (response) {
                alert('Receipt sent to printer!');
            } else {
                console.error('Failed to print receipt');
                alert('Error printing receipt');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Could not connect to printer');
        }
    };

    const handleTestLibraryNodePrint = async () => {

        try {
            // Send the POST request to the local print service
            const response = await axios.post('http://localhost:4000/testlibraryprint');

            if (response) {
                alert('Receipt sent to printer!');
            } else {
                console.log('Failed to print receipt');
                alert('Error printing receipt');
            }
        } catch (error) {
            console.log('Error:', error);
            alert('Could not connect to printer');
        }
    };

    const handleNewLibraryNodePrint = () => {
        if (selectedOrders.length === 0) {
            alert('No orders selected to print');
            return;
        }
        handleNodeEscPosPrint();
        setShowConfirmation(true); // Show confirmation dialog
    };


    const WINDOWS_PRINT_SERVER = 'http://localhost:8080/print';

    const handleNodeEscPosPrint = async () => {
        const selectedOrderDetails = orders.filter(order => selectedOrders.includes(order.orderId));
        const adminName = adminData.name;

        try {
            // Send the POST request to the local print service
            const response = await axios.post(WINDOWS_PRINT_SERVER, {
                selectedOrders: selectedOrderDetails,
                adminName: adminName
            });

            if (response) {
                alert('Receipt sent to printer!');
            } else {
                console.log('Failed to print receipt');
                alert('Error printing receipt');
            }
        } catch (error) {
            console.log('Error:', error);
            alert('Could not connect to printer');
        }
    };

    const handleGetPrinter = async () => {

        try {
            // Send the POST request to the local print service
            const response = await axios.post('http://localhost:4000/getPrinterName');

            if (response) {
                console.log(response);
            } else {
                console.log('Failed to get PrinterNames');
                alert('Error getting PrinterNames');
            }
        } catch (error) {
            console.log('Error:', error);
            alert('Could not get printerNames');
        }
    };

    const handleReactPrint = async () => {
        if (selectedOrders.length === 0) {
            alert('No orders selected to print');
            return;
        }

        await handleReactPrintClick();
        setShowConfirmation(true);
    };

    const handleReactPrintClick = async () => {
        // Filter the selected order details
        const selectedOrderDetails = orders.filter(order => selectedOrders.includes(order.orderId));

        // Create ESC/POS print commands for each selected order
        const printData = await render(
            <Printer type="epson">
                {selectedOrderDetails.map(order => (
                    <div key={order.orderId}>
                        <Text align="center" bold={true}>Kitchen Order Token (KOT)</Text>
                        <Line />
                        <Text align="center">{order.canteenName}</Text>
                        <Line />
                        <Text>Order ID: {order.orderId}</Text>
                        <Text>KOT No: {order.kotNumber}</Text>
                        <Text>Name: {order.userName}</Text>
                        <Text>Ordered Time: {formatDateToOnlyTime(order.orderedTime)}</Text>
                        <Text>Server: {adminData.name}</Text>
                        <Text>Total Price: {order.totalPrice}</Text>
                        {order.isParcel && (
                            <Text align="center" bold={true} style={{ marginTop: '10px' }}>
                                (PARCEL)
                            </Text>
                        )}
                        <Line />
                        <Text>Items:</Text>
                        <Line />
                        {order.foodItems.map(item => (
                            <Text key={item.foodName}>{item.foodName} - {item.foodQuantity}</Text>
                        ))}
                        <Line />
                        <Cut />
                    </div>
                ))}
            </Printer>
        );

        try {
            const port = await window.navigator.serial.requestPort();
            await port.open({ baudRate: 9600 });

            const writer = port.writable?.getWriter();
            if (writer) {
                await writer.write(printData);
                writer.releaseLock();
                console.log('sent data stream for writing')
            } else {
                console.error('Failed to get a writable stream.');
            }

            // Close the serial port after printing is done
            await port.close();
        } catch (error) {
            console.error('Error interacting with the thermal printer:', error);
        }
    };

    const handleTestClick = async () => {

        const printData = await render(
            <Printer type="epson">
                <Text>Hello World</Text>
            </Printer>
        );

        try {
            const port = await window.navigator.serial.requestPort();
            await port.open({ baudRate: 9600 });

            const writer = port.writable?.getWriter();
            if (writer) {
                await writer.write(printData);
                writer.releaseLock();
                console.log('sent data stream for writing')
            } else {
                console.error('Failed to get a writable stream.');
            }

            // Close the serial port after printing is done
            await port.close();
        } catch (error) {
            console.error('Error interacting with the thermal printer:', error);
        }
    };

    return (
        <div className="kot-modal-overlay">
            <div className="kot-modal-content">
                <div className="kot-modal-top-area">
                    <h2>Print KOT</h2>
                    <div className='close' onClick={onClose}>&times;</div>
                </div>
                <div className="kot-modal-header">
                    <select className='kot-selected-dropdown' onChange={handleSelectionLimitChange} value={selectionLimit}>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={40}>40</option>
                        <option value={50}>50</option>
                    </select>
                    <span>{selectedOrders.length} selected</span>
                    <label>
                        <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                        />
                        Select All
                    </label>
                    <FaSync onClick={handleRefresh} size={15} />
                    {/* <button onClick={handlePrintClick}>Print</button> */}
                    <button onClick={handleNewLibraryNodePrint}>Print</button>
                    {/* <button onClick={handleNodePrint}>NodePrint</button> */}
                    {/* <button onClick={handleGetPrinter}>GetPrinterDetails</button> */}
                    {/* <button onClick={handleTestNodePrint}>NodeTestPrint</button> */}
                    {/* <button onClick={handleTestLibraryNodePrint}>NodeLibTestPrint</button> */}
                    {/* <button onClick={handleMultiPrintClick}>2-Print</button> */}
                    {/* <button onClick={handleReactPrint}>ReactPrint</button> */}
                    {/* <button onClick={handleTestClick}>TestReactPrint</button> */}
                </div>
                <div className="kot-modal-body">
                    <table>
                        <thead>
                            <tr>
                                <th>Select</th>
                                <th>Order ID</th>
                                <th>KOT No</th>
                                <th>Name</th>
                                <th>Ordered Time</th>
                                <th>Canteen Name</th>
                                <th>Total Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map((order) => (
                                <tr key={order.orderId}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedOrders.includes(order.orderId)}
                                            onChange={() => handleCheckboxChange(order.orderId)}
                                            disabled={selectedOrders.length >= selectionLimit && !selectedOrders.includes(order.orderId)}
                                        />
                                    </td>
                                    <td>{order.orderId}</td>
                                    <td>{order.kotNumber}</td>
                                    <td>{order.userName}</td>
                                    <td>{formatDateToOnlyTime(order.orderedTime)}</td>
                                    <td>{order.canteenName}</td>
                                    <td>{order.totalPrice}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {showConfirmation && (
                    <div className="confirmation-dialog">
                        <p>Mark the selected items' KOT as printed?</p>
                        <button onClick={confirmPrint}>Yes</button>
                        <button onClick={handleCancelPrint}>No</button>
                    </div>
                )}
            </div>
        </div>
    );
};

const formatDateToOnlyTime = (timestamp) => {
    const date = new Date(timestamp);
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours || 12;
    const formattedHours = String(hours).padStart(2, '0');
    return `${formattedHours}:${minutes} ${ampm}`;
};

export default PrintKOTModal;
