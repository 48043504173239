import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Bar, BarChart, CartesianGrid, Legend, Line, LineChart, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useAuth } from '../../AuthContext';
import { post } from '../../services/api';
import './Analytics.css';

function Analytics() {
  const [duration, setDuration] = useState('30d');
  const sevenDaysBefore = new Date();
  sevenDaysBefore.setDate(sevenDaysBefore.getDate() - 7);
  const [startDate, setStartDate] = useState(sevenDaysBefore);
  const [endDate, setEndDate] = useState(new Date());
  const [validationError, setValidationError] = useState('');
  const { adminData } = useAuth();
  const [selectedCanteenId, setSelectedCanteenId] = useState('All');
  const [analyticsData, setAnalyticsData] = useState({
    totalUsers: 0,
    totalOrders: 0,
    totalRevenue: 0,
    avgOrderValue: 0,
    ordersByDate: [],
    revenueByDate: [],
    mostSoldItems: [],
    mostActiveDeliverers: [],
  });

  const canteenList = adminData
    ? adminData.canteenId.map((id, index) => ({
      canteenId: id,
      canteenName: adminData.canteenName[index]
    }))
    : [];

  useEffect(() => {
    const preferredCanteenId = localStorage.getItem('preferredCanteenId');
    if (preferredCanteenId) {
      setSelectedCanteenId(preferredCanteenId); // Set the dropdown to the stored preferred value
    }
  }, []);

  const handleCanteenChange = (event) => {
    setSelectedCanteenId(event.target.value);
  };

  const handleDurationChange = (event) => {
    setDuration(event.target.value);
  };

  const downloadCSV = () => {
    const csvRows = [];
    const headers = ['Date', 'Orders', 'Revenue'];
    csvRows.push(headers.join(','));

    analyticsData.ordersByDate.forEach((orderData, index) => {
      const revenueData = analyticsData.revenueByDate[index];
      const row = [orderData.date, orderData.orders, revenueData ? revenueData.revenue : 0];
      csvRows.push(row.join(','));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'analytics_report.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  useEffect(() => {
    const fetchAnalyticsData = async () => {
      try {
        let requestBody = {};
        if (selectedCanteenId === 'All') {
          requestBody = { currentTime: Date.now(), duration, canteenIds: adminData.canteenId.map(id => parseInt(id)) };
        } else {
          requestBody = { currentTime: Date.now(), duration, canteenIds: [parseInt(selectedCanteenId)] };
        }
        console.log('requestBody', requestBody);
        if (duration === 'custom') {
          if (endDate <= startDate) {
            setValidationError('End date must be after start date.');
            return;
          }
          const dateDifference = (endDate - startDate) / (1000 * 60 * 60 * 24);
          if (dateDifference < 4) {
            setValidationError('Please select a date range of at least 3 days.');
            return;
          }
          requestBody.startDate = startDate.getTime();
          requestBody.endDate = endDate.getTime();
        }
        setValidationError('');
        const response = await post('v1/analytics/getDurationBasedData', requestBody);
        console.log(response);
        setAnalyticsData(response);
      } catch (error) {
        console.error('Error fetching analytics data:', error);
      }
    };

    fetchAnalyticsData();
  }, [duration, selectedCanteenId, startDate, endDate]);

  const sortedMostSoldItems = [...analyticsData.mostSoldItems].sort((a, b) => b.quantity - a.quantity);


  return (
    <main className="analytics-container">
      <h2>Analytics Panel</h2>
      <div className='analytics-dropdown-container'>
        <select className='analytics-dropdown' value={selectedCanteenId} onChange={handleCanteenChange}>
          <option value="All">All Canteens</option>
          {canteenList.map((canteen) => (
            <option key={canteen.canteenId} value={canteen.canteenId}>
              {canteen.canteenName}
            </option>
          ))}
        </select>
        <select className='analytics-dropdown' value={duration} onChange={handleDurationChange}>
          <option value="7d">Last 7 Days</option>
          <option value="30d">Last 30 Days</option>
          <option value="6m">Last 6 Months</option>
          <option value="1y">Last 1 Year</option>
          <option value="custom">Custom Date Range</option>
        </select>
        {duration === 'custom' && (
          <div className="date-picker-container">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              maxDate={endDate} // Set max date for start date picker to end date
              className="analytics-dropdown" // Apply your custom CSS class
              calendarClassName="custom-datepicker" // Additional class for the calendar
              popperClassName="custom-datepicker-popper" // Popper class
              wrapperClassName="custom-datepicker-wrapper" // Wrapper class
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              minDate={startDate} // Set min date for end date picker to start date
              maxDate={new Date()} // Maximum date as today or earlier
              className="analytics-dropdown" // Apply your custom CSS class
              calendarClassName="custom-datepicker" // Additional class for the calendar
              popperClassName="custom-datepicker-popper" // Popper class
              wrapperClassName="custom-datepicker-wrapper" // Wrapper class
            />
          </div>
        )}

        {validationError && (
          <div className="validation-error">
            {validationError}
          </div>
        )}

        <button className="download-button" onClick={downloadCSV}>Download Reports</button>
      </div>

      <div className="analytics-cards">
        <div className="card">
          <h3>Total Users</h3>
          <h1>{analyticsData.totalUsers}</h1>
        </div>
        <div className="card">
          <h3>Total Orders</h3>
          <h1>{analyticsData.totalOrders}</h1>
        </div>
        <div className="card">
          <h3>Total Revenue</h3>
          <h1>₹{parseFloat(analyticsData.totalRevenue).toFixed(2).toLocaleString()}</h1>
        </div>
        <div className="card">
          <h3>Avg Order Value</h3>
          <h1>₹{analyticsData.avgOrderValue}</h1>
        </div>
      </div>

      <div className="charts">
        <div className="chart-bg">
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={analyticsData.ordersByDate}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="orders" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="chart-bg">
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={analyticsData.revenueByDate}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="revenue" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </div>
        {/* <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie data={analyticsData.ordersByDate} dataKey="orders" nameKey="date" cx="50%" cy="50%" outerRadius={80} fill="#82ca9d" label />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer> */}
        <ResponsiveContainer width="100%" height={450}>
          <BarChart data={sortedMostSoldItems} layout="vertical">
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis type="category" dataKey="foodName" />
            <Tooltip />
            <Legend />
            <Bar dataKey="quantity" fill="#ff6030" />
          </BarChart>
        </ResponsiveContainer>
        <ResponsiveContainer width="100%" height={400}>
          <PieChart>
            <Pie data={analyticsData.mostActiveDeliverers} dataKey="quantity" nameKey="name" cx="50%" cy="50%" outerRadius={150} fill="#23ca9d" label />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
        {/* <ResponsiveContainer width="100%" height={300}>
          <BarChart data={analyticsData.mostActiveDeliverers}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="quantity" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer> */}
      </div>
    </main>
  );
}

export default Analytics;
