import React, { useEffect, useState } from 'react';
import { get } from '../../services/api'; // Import your API service function
import './MenuForm.css'; // Import CSS file for styling if needed

function AddMenuForm({ canteens, onSubmit, onClose }) {
  const [formData, setFormData] = useState({
    foodName: '',
    foodImage: null,
    foodPrice: '',
    stockQuantity: '',
    foodCategory: '', // Category name
    categoryId: '', // Category ID
    vegFlag: 1, // 1 for Veg, 0 for Non-Veg (default is Veg)
    parcelable: 0,
    foodDesc: '', // Food Description
    offerPrice: '', // Nullable, optional offer price
    canteenId: canteens.length > 0 ? canteens[0].canteenId : '',
    menuCreationTime: Date.now(),
    menuModificationTime: Date.now(),
    modifiedBy: localStorage.getItem('adminId')
  });
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (formData.canteenId) {
      fetchCategories(formData.canteenId);
    }
  }, [formData.canteenId]);

  const fetchCategories = async (canteenId) => {
    setIsLoading(true);
    try {
      const response = await get(`v1/categories/${canteenId}`);
      console.log(response);
      setCategories(response);
      if (response.length > 0) {
        setFormData((prevData) => ({
          ...prevData,
          foodCategory: response[0].categoryName, // Set default category name
          categoryId: response[0].categoryId // Set default category ID
        }));
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const name = e.target.name;
    const file = e.target.files[0];
    setFormData({ ...formData, [name]: file });
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = categories.find(category => category.categoryId === parseInt(e.target.value));
    setFormData({
      ...formData,
      foodCategory: selectedCategory.categoryName,
      categoryId: selectedCategory.categoryId
    });
  };

  const handleVegFlagToggle = (e) => {
    setFormData({
      ...formData,
      vegFlag: e.target.checked ? 1 : 0
    });
  };

  const handleParcelFlagToggle = (e) => {
    setFormData({
      ...formData,
      parcelable: e.target.checked ? 1 : 0
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const plainFormData = { ...formData };
    console.log('submitted data: ', plainFormData);
    onSubmit(plainFormData);
  };

  return (
    <div className="add-popup-overlay" onClick={onClose}>
      <div className="add-popup" onClick={(e) => e.stopPropagation()}>
        <span className="close" onClick={onClose}>&times;</span>
        <h2>Add New Menu Item</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="canteenId">Canteen:</label>
            <select
              id="canteenId"
              name="canteenId"
              value={formData.canteenId}
              onChange={handleInputChange}
              required
            >
              {canteens.map((canteen) => (
                <option key={canteen.canteenId} value={canteen.canteenId}>
                  {canteen.canteenName}
                </option>
              ))}
            </select>
          </div>
          {!isLoading && categories.length > 0 && (
            <div className="form-group">
              <label htmlFor="foodCategory">Food Category:</label>
              <select
                id="foodCategory"
                name="foodCategory"
                value={formData.categoryId}
                onChange={handleCategoryChange}
                required
              >
                {categories.map((category) => (
                  <option key={category.categoryId} value={category.categoryId}>
                    {category.categoryName}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="form-group">
            <label htmlFor="vegFlag">Non-Veg/Veg:</label>
            <div className="toggle-container">
              <label className="switch">
                <input
                  type="checkbox"
                  id="vegFlag"
                  name="vegFlag"
                  checked={formData.vegFlag === 1}
                  onChange={handleVegFlagToggle}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="parcelableFlag">Parcel?:</label>
            <div className="toggle-container">
              <label className="switch">
                <input
                  type="checkbox"
                  id="parcelable"
                  name="parcelable"
                  checked={formData.parcelable === 1}
                  onChange={handleParcelFlagToggle}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="foodName">Food Name:</label>
            <input
              type="text"
              id="foodName"
              name="foodName"
              value={formData.foodName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="foodImage">Food Image:</label>
            <input
              type="file"
              id="foodImage"
              name="foodImage"
              onChange={handleFileChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="foodPrice">Food Price:</label>
            <input
              type="number"
              id="foodPrice"
              name="foodPrice"
              value={formData.foodPrice}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="offerPrice">Offer Price (optional):</label>
            <input
              type="number"
              id="offerPrice"
              name="offerPrice"
              value={formData.offerPrice}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="stockQuantity">Stock Quantity:</label>
            <input
              type="number"
              id="stockQuantity"
              name="stockQuantity"
              value={formData.stockQuantity}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="foodDesc">Food Description:</label>
            <textarea
              id="foodDesc"
              name="foodDesc"
              value={formData.foodDesc}
              onChange={handleInputChange}
              rows="3"
            />
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default AddMenuForm;
