import React, { useEffect, useState } from 'react';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { useAuth } from '../../AuthContext';
import { get, post, put } from '../../services/api';
import './Category.css';

function Category() {
  const { adminData } = useAuth();
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCanteenId, setSelectedCanteenId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const canteenList = adminData
    ? adminData.canteenId.map((id, index) => ({
      canteenId: id,
      canteenName: adminData.canteenName[index]
    }))
    : [];

  useEffect(() => {
    const preferredCanteenId = localStorage.getItem('preferredCanteenId');
    if (preferredCanteenId) {
      setSelectedCanteenId(preferredCanteenId);
    } else if (canteenList.length > 0) {
      setSelectedCanteenId(canteenList[0].canteenId);
    }
  }, [adminData]);

  const handleCanteenChange = (event) => {
    setSelectedCanteenId(event.target.value);
  };

  const fetchCategories = async () => {
    setIsLoading(true);
    try {
      const response = await get(`v1/categories/${selectedCanteenId}`);
      setCategories(response);
    } catch (error) {
      console.error('Error fetching categories:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCanteenId) {
      fetchCategories();
    }
  }, [selectedCanteenId]);

  const handleAddCategory = () => {
    setSelectedCategory(null);
    setShowModal(true);
  };

  const handleEditCategory = (category) => {
    setSelectedCategory(category);
    setShowModal(true);
  };

  const handleDeleteCategory = async (categoryId) => {
    if (window.confirm('Are you sure you want to delete this category?')) {
      try {
        await put(`v1/categories/delCategory/${categoryId}`);
        fetchCategories();
      } catch (error) {
        console.error('Error deleting category:', error);
      }
    }
  };

  const handleSubmit = async (formData) => {
    setIsLoading(true);
    try {
      if (selectedCategory) {
        await put(`v1/categories/${selectedCategory.categoryId}`, formData);
      } else {
        await post(`v1/categories/${selectedCanteenId}`, formData);
      }
      fetchCategories();
    } catch (error) {
      console.error('Error saving category:', error);
    } finally {
      setShowModal(false);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h2 className='category-title'>Categories</h2>
      <div className="category-top-area">
        <select className="canteen-dropdown" onChange={handleCanteenChange} value={selectedCanteenId}>
          {canteenList.map((canteen) => (
            <option key={canteen.canteenId} value={canteen.canteenId}>
              {canteen.canteenName}
            </option>
          ))}
        </select>
        <button className="add-button" onClick={handleAddCategory}>Add Category</button>
      </div>
      {isLoading ? (
        <p>Loading categories...</p>
      ) : (
        <div className="category-grid">
          {categories.map((category) => (
            <div key={category.categoryId} className="category-card">
              <h3>{category.categoryName}</h3>
              <div className="category-card-actions">
                <button onClick={() => handleEditCategory(category)}><BiEdit /></button>
                <button onClick={() => handleDeleteCategory(category.categoryId)}><BiTrash /></button>
              </div>
            </div>
          ))}
        </div>
      )}
      {showModal && (
        <CategoryForm
          category={selectedCategory}
          onClose={() => setShowModal(false)}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
}

function CategoryForm({ category, onClose, onSubmit }) {
  const [categoryName, setCategoryName] = useState(category ? category.categoryName : '');
  const [startTime, setStartTime] = useState(category ? category.startTime : '');
  const [endTime, setEndTime] = useState(category ? category.endTime : '');
  const [validationError, setValidationError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate start and end time
    if (!validateTime(startTime) || !validateTime(endTime)) {
      setValidationError('Please enter valid start and end times.');
      return;
    }

    // Convert times to minutes for easier comparison
    const startTimeInMinutes = convertTimeToMinutes(startTime);
    const endTimeInMinutes = convertTimeToMinutes(endTime);

    // Validate start and end time
    if (endTimeInMinutes <= startTimeInMinutes) {
      setValidationError('End time should be greater than the start time.');
      return;
    }

    // Validate time gap (at least 15 minutes)
    if (endTimeInMinutes <= startTimeInMinutes + 15) {
      setValidationError('The gap between start and end times must be at least 15 minutes.');
      return;
    }

    // Submit form
    try {
      await onSubmit({ categoryName, startTime, endTime });
      onClose();
    } catch (error) {
      console.error('Error saving category:', error);
    }
  };

  // Helper function to validate time format (HH:mm)
  const validateTime = (time) => {
    const regex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
    return regex.test(time);
  };

  // Helper function to convert time to minutes since midnight
  const convertTimeToMinutes = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  return (
    <div className="category-popup-overlay">
      <div className="category-popup">
        <h2>{category ? 'Edit Category' : 'Add Category'}</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Category Name
            <input
              type="text"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              required
            />
          </label>
          <div>
            <label>
              Start Time
              <input
                type="time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <label>
              End Time
              <input
                type="time"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                required
              />
            </label>
          </div>
          {validationError && <p className="error-message">{validationError}</p>}
          <div className="category-popup-actions">
            <button type="submit">Save</button>
            <button type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Category;
